import React from 'react'

import Layout from 'src/components/layout/layout'
import Seo from 'src/components/seo'
import Hero from 'src/components/offerSections/hero'
import Cars, { Car } from 'src/components/offerSections/cars'
import { graphql } from 'gatsby'

interface OfferPageProps {}

const OfferPage: React.FC<OfferPageProps> = ({ data }) => {
  const cars: Car[] = data.contentfulOfferSection.cars

  return (
    <Layout>
      <Seo
        title='Sprawdź samochody w naszej ofercie - JM Car Company'
        description='Oferujemy zachodnie samochody używane oraz poleasingowe. Samochody pochodzą z zachodnich salonów sprzedaży, spółek leasingowych oraz banków.'
      />
      <Hero />
      <Cars cars={cars} />
    </Layout>
  )
}

export const query = graphql`
  query {
    contentfulOfferSection {
      cars {
        id
        carName
        carShortDescription
        carType
        carYear
        carMileage
        carEngineCapacity
        carEngineType
        carPrice
        slug
        sold
        reserved
        carSmallImage {
          fluid {
            srcSet
            src
            sizes
            aspectRatio
          }
        }
      }
    }
  }
`

export default OfferPage
